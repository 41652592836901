import { Component, OnInit, Input } from "@angular/core";
import { Router } from '@angular/router';
import { UserService } from './front/elements/login/user.service';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { TerminalService } from './terminal/terminal.service';

@Component({
  selector: "app",
  templateUrl: "./app/app.view.html"
})
export class AppComponent implements OnInit {

  constructor( private userService: UserService, private router: Router,
  private terminalService: TerminalService ) {
  }

  ngOnInit() {
    console.log("Application component initialized ...");
    
    if (this.terminalService.terminalHasCredentials()) {
      this.access();
    } else {
      this.router.navigate(['initial']);
    }
  }

  access() {
    this.terminalService.checkIfIsValid().subscribe(response => {
      if ( response ) {
        localStorage.setItem('validate', 'true');
        if ( this.userService.isLoggedIn() ) {
          document.getElementsByTagName('body')[0].className = '';
          this.router.navigate(['header/sellTickets/chooseTravel']);
          this.terminalService.getTheme().subscribe(response => {
          });
        } else {
          document.getElementsByTagName('body')[0].className = 'backgroundLogin';
          this.terminalService.getTheme().subscribe(response => {
          });
          this.router.navigate(['login']);
        } 
      }
    }, error =>{
        if ( error.code === "401-00") {
          this.router.navigate(['initial']);
        }
    });
  }

}